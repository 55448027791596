@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: 'Montserrat', sans-serif;
}
.banner-gradient{
    position: absolute;
    z-index: 99;
    font-size: 36px;
    width: 100%;
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(to right, rgb(255, 97, 6) 20%, rgba(255, 97, 6, 0.603) 35%, rgba(255, 102, 0, 0) 100%);
}
.colorOrange{
    color: orange;
}

.title{
    position: absolute;
    z-index: 100;
    margin-top: 10%;
    font-size: 36px;
    color: white;
    margin-left: 5%;
    font-weight: 700;
}
.subtitle{
    position: absolute;
    z-index: 100;
    margin-top: 15%;
    font-size: 24px;
    color: rgb(255, 255, 255);
    margin-left: 5%;
    font-weight: 200;
}

.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}